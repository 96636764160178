import React from 'react';

import GoalsImage from '../assets/hero1.svg';

type GoalsType = {
  title: string;
  description: string;
};

export default function Goals({ title, description }: GoalsType) {
  return (
    <div id="goals" className="bg-gray-300 py-10 px-6">
      <div className="container mx-auto">
        <div className="flex flex-wrap mb-4">
          <div className="md:w-1/2 py-12 md:px-12 my-auto">
            <h3 className="text-4xl font-bold mb-6 leading-tight">{title}</h3>
            <p className="text-xl mb-6">{description}</p>
            <div className="text-left">
              <a
                type="button"
                className="bg-indigo-900 hover:bg-indigo-700 font-bold rounded-full py-4 px-8 shadow-lg uppercase tracking-wider text-white border-indigo-300"
                href="https://cdn.forms-content.sg-form.com/89715861-5a5d-11ea-ba37-926dcde01e69"
                target="_blank"
                rel="noopener noreferrer"
              >
                Be a beta tester
              </a>
            </div>
          </div>
          <div className="md:w-1/2 -my-24 md:my-0">
            <GoalsImage className="mx-auto w-full" />
          </div>
        </div>
      </div>
    </div>
  );
}
