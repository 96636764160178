import React from 'react';

export default function BoxServices({ title, text, icon }) {
  return (
    <>
      {icon}
      <h4 className="text-xl font-bold text-center my-8">{title}</h4>
      <p>{text}</p>
    </>
  );
}
