import React, { useState } from 'react';
import { Link as A } from 'gatsby';
import { Link, animateScroll as scroll } from 'react-scroll';

import Logo from '../assets/logo.svg';

import './navbar.css';

export default function Navbar() {
  const [open, setOpen] = useState(false);
  const toggleMenu = () => {
    setOpen(!open);
  };
  return (
    <nav>
      <div className="container mx-auto px-6 py-2 flex justify-between items-center mt-4 relative">
        <a className="font-bold text-2xl lg:text-4xl" href="/">
          <Logo className="logo" />
        </a>
        <div className="hidden lg:block">
          <ul className="inline-flex">
            <li>
              <Link
                activeClass="active"
                to="howwork"
                spy
                smooth
                offset={-70}
                duration={600}
                className="px-4 hover:text-gray-600 cursor-pointer"
              >
                How it works
              </Link>
            </li>
            <li>|</li>
            <li>
              <Link
                activeClass="active"
                to="usecases"
                spy
                smooth
                offset={-70}
                duration={600}
                className="px-4 hover:text-gray-600 cursor-pointer"
              >
                Use Cases
              </Link>
            </li>
            <li>|</li>
            <li>
              <Link
                activeClass="active"
                to="faq"
                spy
                smooth
                offset={-70}
                duration={600}
                className="px-4 hover:text-gray-600 cursor-pointer"
              >
                FAQ
              </Link>
            </li>

            {/* <li className="bg-indigo-700">
              <A
                to="/es"
                className="text-white px-4 hover:text-gray-300 cursor-pointer"
                title="Cambiar a español"
              >
                ES
              </A>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
}
